import { v4 as uuidv4 } from 'uuid'
import s3ParseKey from '@/utils/s3ParseKey'
import ErrorMsgs from '@/classes/ErrorMsgs'
import FileTypes from '@/classes/FileTypes'
import FileStatus from '@/classes/FileStatus'
import FileError from '@/classes/FileError'
import CrossValidateErrors from '@/classes/CrossValidateErrors'
import SessionRefresh from '@/classes/SessionRefresh'

export default class UploadFile {
  constructor() {
    this.filename = '???'
    this.size = null
    this.progress = 100
    this.obj = null
    this.status = null
    this.statusText = ''
    this.typeGroup = null
    this.key = null
    this.shortKey = null
    this.errors = []
    this.localErrors = []
    this.simpleErrors = []
    this.disableDownload = false
    this.isDeleted = false
  }

  static createFromApiFileCompressed(apiFileRaw) {
    const key = apiFileRaw?.k
    const { uploadUuid, filename } = s3ParseKey(key)
    const apiFile = {
      key,
      uploadUuid,
      filename,
      errors: apiFileRaw?.e || [],
      status: apiFileRaw?.s === 'P' ? FileStatus.PENDING : 'INVALID',
      disableDownload: !!apiFileRaw?.d,
    }

    return UploadFile.createFromApiFile(apiFile)
  }

  static createFromApiFile(apiFile) {
    const thisFile = new UploadFile()

    thisFile.uploadUuid = apiFile.uploadUuid
    thisFile.key = apiFile.key
    thisFile.filename = apiFile.filename
    thisFile.size = null
    thisFile.type = null
    thisFile.status = apiFile.status
    thisFile.disableDownload = apiFile.disableDownload

    if (apiFile.errors && Array.isArray(apiFile.errors) && apiFile.errors.length) {
      thisFile.simpleErrors = apiFile.errors.map(FileError.createFromApi)
    }

    thisFile.refreshTypeGroup()
    thisFile.refreshErrors()

    return thisFile
  }

  static createFromLocalFile(localFile, tempFile = null) {
    const thisFile = new UploadFile()

    // normalise filename extension to lowercase and convert .jpeg to .jpg
    thisFile.filename = localFile.name.replace(/^(.*\.)([^.]+)$/, (full, basename, rawExt) => {
      let ext = rawExt.toLowerCase()
      if (ext === 'jpeg') {
        ext = 'jpg'
      }

      return basename + ext
    })

    thisFile.progress = 0
    thisFile.size = localFile.size
    thisFile.type = localFile.type
    thisFile.obj = localFile
    thisFile.uploadUuid = tempFile ? tempFile.uploadUuid : uuidv4()

    // fix issue #81 - "*" in the uploaded file name results in 403
    thisFile.shortKey = `${thisFile.uploadUuid}-${thisFile.filename.replace(/\*/g, '[[ASTERISK]]')}`

    thisFile.refreshTypeGroup()

    // check if file is too big for upload
    if (thisFile.isTooBig()) {
      thisFile.setTooBigState()
    }

    return thisFile
  }

  resetForReupload() {
    this.uploadUuid = uuidv4()
    this.shortKey = `${this.uploadUuid}-${this.filename.replace(/\*/g, '[[ASTERISK]]')}`
  }

  getId() {
    return this.uploadUuid
  }

  progressCallback(progressEvent) {
    SessionRefresh.keepAlive()

    const { loaded, total } = progressEvent
    this.progress = Math.min(Math.ceil(100 * (loaded / total)), 99)
    this.statusText = `${loaded} / ${total} uploaded, ${this.progress}% complete`
    console.log(`FILE upload progress: ${this.statusText}`, {
      filename: this.filename,
      uuid: this.getId(),
    })
  }

  refreshTypeGroup() {
    this.typeGroup = FileTypes.getFileTypeGroup(this)
  }

  refreshErrors() {
    if (this.status === FileStatus.UPLOADING) {
      this.errors = [...this.localErrors]

      return
    }

    this.errors = [...this.localErrors, ...this.simpleErrors]

    // add cross validation errors
    this.errors.push(...CrossValidateErrors.getErrorsForFile(this).map(FileError.createFromApi))
    this.errors.sort(FileError.sortDefault)
    if (this.status === FileStatus.VALID || this.status === FileStatus.INVALID) {
      this.status = this.errors.length ? FileStatus.INVALID : FileStatus.VALID
    }
  }

  getAllErrors() {
    this.refreshErrors()

    return this.errors
  }

  // active upload
  setUploadingState() {
    this.status = FileStatus.UPLOADING
    this.errors = []
    this.simpleErrors = []
    this.refreshErrors()

    // set a small amount of dummy progress (no updates shown for first 5MB in resumable mode)
    this.progressCallback({
      loaded: 100 + Math.ceil(Math.random() * 2000),
      total: this.size,
    })
  }

  setUploadQueueState() {
    this.status = FileStatus.UPLOADING
    this.statusText = 'Uploading queued ...'
    this.progress = 0
    this.errors = []
    this.simpleErrors = []
    this.refreshErrors()
  }

  setUploadRetryState(statusText = 'Uploading failed, will retry ...') {
    this.status = FileStatus.UPLOADING
    this.statusText = statusText
    this.errors = []
    this.localErrors = [FileError.createFromApi({ message: statusText })]
    this.simpleErrors = []
    this.refreshErrors()
  }

  setProcessingState() {
    this.status = FileStatus.PENDING
    this.progress = 100
    this.statusText = ''
  }

  setUploadFailedState(message = 'There was a problem uploading this file. Please contact us for details.') {
    this.status = FileStatus.INVALID
    this.statusText = 'Upload failed'
    this.progress = 0
    this.errors = []
    this.localErrors = [FileError.createFromApi({ message })]
    this.refreshErrors()
  }

  setTooBigState() {
    this.setUploadFailedState(ErrorMsgs.FileSizeTooBigError)
  }

  /**
   * Get short version of key, relative to Storage config: uploadUuid + filename (remove organisationUuid and cognitoIdentityId)
   */
  getShortKey() {
    return this.key ? this.key.split('/').pop() : this.shortKey
  }

  getErrorCount() {
    return this?.errors?.length || 0
  }

  hasErrors() {
    return this?.errors?.length > 0
  }

  getErrorSummaryTextArray() {
    if (!this.errors) {
      return []
    }

    const displayRowCol = this.isMetadata()
    const errs = this.errors.slice(0, 3).map(error => error.getTextSummary(displayRowCol))
    if (this.errors.length > 3) {
      errs[2] += ' ...'
    }

    return errs
  }

  getStatus() {
    if (CrossValidateErrors.isValidating() && this.status !== FileStatus.UPLOADING) return FileStatus.PENDING

    return this.status
  }

  getStatusText() {
    switch (this.status) {
      case FileStatus.INVALID:
        return this.statusText || 'Validation errors found'
      case FileStatus.UPLOADING:
        return this.statusText
      case FileStatus.PENDING:
        return 'Upload complete - processing...'
      case FileStatus.VALID:
      default:
        return ''
    }
  }

  isAudio() {
    return this.typeGroup === FileTypes.AUDIO
  }

  isMetadata() {
    return this.typeGroup === FileTypes.METADATA
  }

  isUnsupported() {
    return this.typeGroup === FileTypes.UNSUPPORTED
  }

  canBeIgnored() {
    // ignore macOS files
    return this.filename.startsWith('._') || this.filename === '.DS_Store'
  }

  isTooBig() {
    return this.size > +process.env.VUE_APP_MAX_FILE_SIZE
  }

  isUploaded() {
    return this.progress === 100
  }

  isDownloadable() {
    return (
      this.isUploaded() && // has finished uploading
      !this.disableDownload && // is not marked as disable download
      this.status !== FileStatus.PENDING // is not unprocessed
    )
  }

  simpleErrorsCount() {
    return this.simpleErrors.length + this.localErrors.length
  }
}
