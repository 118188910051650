<template>
  <v-menu
    v-if="User.isLoggedIn"
    attach="#appTopBar"
    offset-x
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" v-on="on">
          <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1"> {{ userDisplayName }} </span>
          <small class="text--disabled text-capitalize">Workspace User</small>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>

      <!-- Admin -->
      <v-list-item v-if="User.isAdmin" link :to="{ name: 'admin' }" exact>
        <v-list-item-icon class="me-2">
          <v-icon size="22">{{ icons.mdiAccountLock }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Admin </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Logout -->
      <v-list-item link @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">{{ icons.mdiLogoutVariant }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Logout </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { Auth } from 'aws-amplify'
import {
  mdiAccountOutline,
  mdiAccountLock,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import User from '@/classes/User'
import onUserLoaded from '@/utils/onUserLoaded'

export default {
  setup() {
    return {
      isLoggedIn: false,
      userDisplayName: '',
      icons: {
        mdiAccountOutline,
        mdiAccountLock,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
      User,
    }
  },
  mounted() {
    onUserLoaded(user => {
      if (user) {
        // create a pseudo display name from the user's email address
        this.userDisplayName = user.attributes.email
          .split('@')[0]
          .replace('.', ' ')
          .replace(/\b\w/g, l => l.toUpperCase())
        this.isLoggedIn = true
      } else {
        this.userDisplayName = ''
        this.isLoggedIn = false
      }
    })
  },
  methods: {
    async logoutUser() {
      try {
        await Auth.signOut()
        this.userDisplayName = ''
        this.isLoggedIn = false
      } catch (error) {
        console.log('error signing out: ', error)
      }
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
