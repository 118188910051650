<template>
  <div v-if="!accepted" class="cookie-banner">
    We use Strictly Necessary Cookies to operate this website. These cookies are essential and will always remain
    active. This site does not use any Optional Cookies, such as Performance, Functional or Targeting cookies. For more
    information, review our
    <router-link :to="{ name: 'cookies' }" target="_blank">Cookie Policy</router-link>
    <button class="close-button" @click="acceptCookies">
      <v-icon size="24" color="#999">{{ icons.mdiClose }}</v-icon>
    </button>
  </div>
</template>

<script>
import icons from '@/utils/icons'

export default {
  data() {
    return {
      accepted: false,
      icons,
    }
  },
  mounted() {
    if (localStorage.getItem('cookiesAccepted')) {
      this.accepted = true
    }
  },
  methods: {
    acceptCookies() {
      this.accepted = true
      localStorage.setItem('cookiesAccepted', true)
    },
  },
}
</script>

<style>
.cookie-banner {
  position: fixed;
  bottom: 50px;
  left: 15%;
  right: 15%;
  background-color: #333;
  color: #fff;
  padding: 30px;
  padding-right: 80px;
  z-index: 10;
  border-radius: 5px;
}

.close-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  color: #ccc;
}
</style>
