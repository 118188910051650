import VueFullScreenFileDropBase from 'vue-full-screen-file-drop'
import 'vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css'
import './plugins/vue-composition-api'
import './styles/styles.scss'
import { Translations } from '@aws-amplify/ui-components'
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader'
import Vue from 'vue'
import Axios from 'axios'
import { Amplify, I18n } from 'aws-amplify'
import { amplify } from '@/config'
// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoUser } from 'amazon-cognito-identity-js'
import SessionRefresh from '@/classes/SessionRefresh'
import EventBus from '@/classes/EventBus'
import FileStorage from '@/classes/FileStorage'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './utils/global-filters'
import onUserLoaded from './utils/onUserLoaded'
import DropHandler from './classes/DropHandler'

Vue.config.ignoredElements = [/amplify-\w*/]

applyPolyfills().then(() => {
  defineCustomElements(window)
})

Amplify.configure(amplify)

// Configure amplify storage to use the organisationUuid as prefix (when user loaded)
onUserLoaded(FileStorage.configureForUser)

Vue.prototype.$http = Axios

Vue.config.productionTip = false

Vue.component('VueFullScreenFileDropBase', VueFullScreenFileDropBase)

const VueFullScreenFileDrop = Vue.component('VueFullScreenFileDrop', {
  extends: VueFullScreenFileDropBase,
  methods: {
    async onDrop(e) {
      e.preventDefault()
      this.visible = false
      this.$forceUpdate()
      DropHandler.onDropEvent(e)
    },
  },
})

// Override Amplify UI language strings
I18n.setLanguage('en-US')
I18n.putVocabulariesForLanguage('en-US', {
  // Set new password required hint
  [Translations.NEW_PASSWORD_LABEL]:
    'Your new password must be at least 16 characters and contain a mixture of upper and lowercase letters, numbers and symbols.',

  // MFA Instructions
  [Translations.TOTP_ISSUER]: 'Sony Bulk Uploader',
  [Translations.TOTP_HEADER_TEXT]: 'Setup MFA for additional security',
  [Translations.TOTP_LABEL]:
    'Use an MFA Authenticator app (we recommend "Google Authenticator" for Android/iOS) to scan the QR code above. ' +
    'Enter the 6-digit security code provided by the app in the box below and click "VERIFY" ' +
    '(Please note that codes are time-sensitive).',
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'VERIFY',
  [Translations.TOTP_SETUP_FAILURE]: 'MFA Setup has failed',
  [Translations.CONFIRM_TOTP_CODE]: 'MFA Security Check',
})

// MFA/Email code label (switch later)
Translations.CODE_LABEL_MFA =
  'Please enter a new 6-digit code generated by the MFA authenticator app you have set up with.'
Translations.CODE_LABEL_EMAIL = 'Please enter the 6-digit code we just sent to your email address.'

// Setup keep alive on mousemove event
// eslint-disable-next-line no-restricted-globals
addEventListener('mousemove', () => {
  SessionRefresh.keepAlive()
})

// Modify zoom level
document.body.style.zoom = '80%'

// FC-614 Display an error when a user enters an invalid code during MFA setup
CognitoUser.prototype.verifySoftwareTokenOrig = CognitoUser.prototype.verifySoftwareToken
CognitoUser.prototype.verifySoftwareToken = function verifySoftwareToken(totpCode, friendlyDeviceName, callback) {
  const { onFailure } = callback
  const callbackNew = { ...callback }
  callbackNew.onFailure = err => {
    if (err.code === 'EnableSoftwareTokenMFAException') {
      alert('The MFA code you entered does not match the expected code. Please try again.')
    } else {
      alert(totpCode.length !== 6 ? 'The MFA code must contain exactly 6 digits' : err.message)
    }

    return onFailure(err)
  }

  return this.verifySoftwareTokenOrig(totpCode, friendlyDeviceName, callbackNew)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  VueFullScreenFileDrop,
}).$mount('#app')

// force redirect on logout
EventBus.$on('logout', () => {
  router.push({ name: 'dashboard' })
})
