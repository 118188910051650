import Vue from 'vue'
import Vuex from 'vuex'
import FileStorage from '@/classes/FileStorage'
import DB from '@/classes/DB'
import ErrorMsgs from '@/classes/ErrorMsgs'
import EventBus from '@/classes/EventBus'
import User from '@/classes/User'
import removeFromArray from '@/utils/removeFromArray'

Vue.use(Vuex)

const MAX_CONCURRENT_UPLOADS = 2

let uploadUserId
let checkPreUploadQueue

const initiateUpload = async (state, thisFile, attempt = 1) => {
  state.activeUploadFileList.push(thisFile)
  thisFile.setUploadingState()

  const completeCallback = () => {
    thisFile.setProcessingState()
    EventBus.$emit('newFileUploaded', thisFile)
    removeFromArray(state.activeUploadFileList, thisFile)
    removeFromArray(state.uploadingFileList, thisFile)
    checkPreUploadQueue(state)
  }

  const errorCallback = error => {
    // log the error for debug
    console.error(`ERROR uploading ${thisFile.filename}`)
    console.error(`ATTEMPT: ${attempt}`)
    console.error('FULL ERROR:', error)

    // parse the error to determine further handling
    let retry = true
    let message = 'Unexpected error while uploading'
    if (error.name === 'CanceledError') {
      retry = false
      message = 'Upload has been cancelled'
    } else if (error.message === 'FileSizeTooBigError') {
      retry = false
      message = ErrorMsgs.FileSizeTooBigError
    } else if (error.message === 'MaxFileCountError') {
      retry = false
      message = ErrorMsgs.MaxFileCountError
    } else if (error.code === 'ERR_BAD_REQUEST') {
      if (error?.response?.data.includes('EntityTooLarge')) {
        retry = false
        message = ErrorMsgs.FileSizeTooBigError
      } else if (error?.response?.data.includes('expired')) {
        retry = true
        message = 'Upload timeout, will retry...'
      }
    }

    // log parse result
    console.error('RETRY?', retry)
    console.error('MESSAGE:', message)

    // set retry or failed state
    if (retry) {
      thisFile.setUploadRetryState(message)
    } else {
      thisFile.setUploadFailedState(message)
    }

    // remove file from the active upload list
    removeFromArray(state.activeUploadFileList, thisFile)

    // retry?
    if (retry && !thisFile.isDeleted) {
      // add back to queue if retrying
      thisFile.resetForReupload()
      state.preUploadQueueFileList.push(thisFile)
    } else {
      // remove from "all uploads" list
      removeFromArray(state.uploadingFileList, thisFile)
    }

    // get next item from queue
    checkPreUploadQueue(state)
  }

  try {
    await FileStorage.upload(thisFile, completeCallback, errorCallback)
  } catch (err) {
    errorCallback(err)
  }
}

// grab another file from the queue
checkPreUploadQueue = state => {
  // check if still logged in
  if (!User.isLoggedIn) {
    return
  }

  // check we're not already uploading MAX_CONCURRENT_UPLOADS
  if (state.activeUploadFileList.length >= MAX_CONCURRENT_UPLOADS) {
    return
  }

  // if anything in the queue, initiate upload
  if (state.preUploadQueueFileList.length > 0) {
    const thisFile = state.preUploadQueueFileList.shift()
    initiateUpload(state, thisFile)
  }
}

// hard delete - remove from all queues
const deleteUpload = (state, thisFileRaw) => {
  const thisFile = thisFileRaw
  thisFile.isDeleted = true
  const wasUploading = removeFromArray(state.activeUploadFileList, thisFile)
  if (wasUploading) {
    FileStorage.cancel(thisFile)
  }
  removeFromArray(state.preUploadQueueFileList, thisFile)
  removeFromArray(state.uploadingFileList, thisFile)
  checkPreUploadQueue(state)
}

const store = new Vuex.Store({
  state: {
    userList: [],
    uploadingFileList: [],
    activeUploadFileList: [],
    preUploadQueueFileList: [],
    rejectedFileList: [],
  },
  mutations: {
    async addFile(state, thisFile) {
      const isFinal = !!thisFile.obj
      if (thisFile.canBeIgnored()) {
        return
      }

      if (thisFile.isUnsupported()) {
        if (isFinal) {
          state.rejectedFileList.push(thisFile)
        }

        return
      }

      // skip if max file count has been reached (display in UI)
      if (DB.hasMaxFileCount()) {
        thisFile.setUploadFailedState(ErrorMsgs.MaxFileCountError)
        DB.addUpload(thisFile)

        return
      }

      // exit early if file is too big - but display in UI
      if (thisFile.isTooBig()) {
        DB.addUpload(thisFile)

        return
      }

      // record current user id
      uploadUserId = User.getId()

      // add to uploading list
      thisFile.setUploadQueueState()
      if (isFinal) {
        state.uploadingFileList.push(thisFile)
      }
      DB.addUpload(thisFile)

      if (!isFinal) {
        return
      }

      // upload now or add to queue?
      if (state.activeUploadFileList.length >= MAX_CONCURRENT_UPLOADS) {
        state.preUploadQueueFileList.push(thisFile)
      } else {
        initiateUpload(state, thisFile)
      }
    },
    async resumeUploads(state) {
      // security check - torch all if user id does not match
      if (User.getId() !== uploadUserId) {
        state.uploadingFileList.length = 0
        state.activeUploadFileList.length = 0
        state.preUploadQueueFileList.length = 0

        return
      }

      // add files back to ui
      state.uploadingFileList.forEach(thisFile => {
        DB.addUpload(thisFile)
      })
      EventBus.$emit('newFileAdded')

      // start uploading
      for (let i = 0; i < MAX_CONCURRENT_UPLOADS; i += 1) {
        checkPreUploadQueue(state)
      }
    },

    // "soft" cancel - cancel all active uploads and move them to the preupload queue
    async cancelAllUploads(state) {
      state.activeUploadFileList.forEach(thisFile => {
        FileStorage.cancel(thisFile)
        state.preUploadQueueFileList.push(thisFile)
      })
      state.activeUploadFileList.length = 0
    },
  },
  actions: {},
  modules: {},
})

EventBus.$on('fileDeleted', thisFile => {
  deleteUpload(store.state, thisFile)
})

export default store
