import Vue from 'vue'

Vue.filter('str_limit', (valueX, size) => {
  if (!valueX) return ''
  const value = valueX.toString()
  if (value.length <= size) {
    return value
  }

  return `${value.substr(0, size)}...`
})

Vue.filter('ucfirst', valueX => valueX.charAt(0).toUpperCase() + valueX.slice(1))
