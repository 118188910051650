import { Auth } from 'aws-amplify'
import UploadsApi from './UploadsApi'

const keepAliveDelay = 180000 // 3 mins

let lastKeepAlive = Date.now() - 30000

export default {
  async keepAlive() {
    const ts = Date.now()
    if (ts - lastKeepAlive > keepAliveDelay) {
      lastKeepAlive = ts
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        this.refreshToken(cognitoUser)
      } catch (e) {
        return
      }
      UploadsApi.keepAlive()
    }
  },
  async refreshToken(cognitoUser) {
    try {
      const currentSession = await Auth.currentSession()
      cognitoUser.refreshSession(currentSession.refreshToken, err => {
        if (err) {
          console.error('ERROR (CALLBACK) - REFRESH SESSION FAILED (retrying)', err)
          this.refreshToken(cognitoUser)
        }
      })
    } catch (e) {
      console.error('ERROR (CATCH) - REFRESH SESSION FAILED (retrying)', e)
      this.refreshToken(cognitoUser)
    }
  },
}
