import formatBytes from '@/utils/formatBytes'

const ErrorMsgs = {
  FileSizeTooBigError: `The upload failed because this file is too big. Max file size is ${formatBytes(
    process.env.VUE_APP_MAX_FILE_SIZE,
  )}`,
  MaxFileCountError: `Max file count of ${process.env.VUE_APP_MAX_SUBMISSION_ROWS} has been reached`,
}

export default ErrorMsgs
