import store from '@/store'
import EventBus from '@/classes/EventBus'
import UploadFile from '@/classes/UploadFile'

const chunkSize = 3
const droppedFiles = []

let readEntriesRecursive

const traverseFileTree = async item => {
  if (item.isFile) {
    droppedFiles.push(item)
  } else if (item.isDirectory) {
    const dirReader = item.createReader()
    readEntriesRecursive(dirReader)
  }
}

// https://stackoverflow.com/questions/18815197/javascript-file-dropping-and-reading-directories-asynchronous-recursion
readEntriesRecursive = dirReader => {
  dirReader.readEntries(async entries => {
    if (!entries.length) {
      return
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < entries.length; i++) {
      traverseFileTree(entries[i])
    }

    readEntriesRecursive(dirReader)
  })
}

const DropHandler = {
  disabled: false,

  isProcessing: () => !!droppedFiles.length,

  processingCount: () => droppedFiles.length,

  onDropEvent: async e => {
    if (DropHandler.disabled) {
      return
    }

    const { items } = e?.dataTransfer
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry()
      if (item) {
        traverseFileTree(item)
      }
    }

    // todo: fallback if webkitGetAsEntry is not supported?
    // for (let i = 0; i < files.length; i += 1) {
    //   const fileInfo = files[i]
    //   // ...
    // }

    setTimeout(() => {
      DropHandler.processDroppedFiles()
    }, 100)
  },

  processDroppedFiles: async () => {
    const chunk = droppedFiles.splice(0, chunkSize)
    chunk.forEach(item => {
      item.file(fileInfo => {
        const thisFile = UploadFile.createFromLocalFile(fileInfo)
        store.commit('addFile', thisFile)
      })
    })

    setTimeout(() => {
      EventBus.$emit('newFileAdded')
      if (droppedFiles.length) {
        DropHandler.processDroppedFiles()
      }
    }, 100)
  },
}

export default DropHandler
