<template>
  <layout-content>
    <div>
      <amplify-authenticator style="height: 300px">
        <slot></slot>
        <amplify-sign-in slot="sign-in" :hide-sign-up="true"></amplify-sign-in>
      </amplify-authenticator>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutContent,
  },
}
</script>
