<template>
  <div>
    <v-card-title> Search Organisations </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="groupSearch"
          label="Organisation Name, ID"
          outlined
          background-color="white"
          @keyup.native="onFilterChange"
        ></v-text-field>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import EventBus from '@/classes/EventBus'

export default {
  data() {
    return {
      groupSearch: '',
    }
  },
  methods: {
    async onFilterChange() {
      const filterEvent = {
        groupSearch: this.groupSearch,
      }
      EventBus.$emit('adminGroupListFilter', filterEvent)
    },
  },
}
</script>
