const FileStatus = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  UPLOADING: 'UPLOADING',
  PENDING: 'PENDING',
}

FileStatus.label = {}
FileStatus.label[FileStatus.VALID] = 'VALID'
FileStatus.label[FileStatus.INVALID] = 'INVALID'
FileStatus.label[FileStatus.PENDING] = 'PROCESSING'
FileStatus.label[FileStatus.UPLOADING] = 'UPLOADING'

FileStatus.color = {}
FileStatus.color[FileStatus.VALID] = 'success'
FileStatus.color[FileStatus.INVALID] = 'error'
FileStatus.color[FileStatus.PENDING] = 'warning'
FileStatus.color[FileStatus.UPLOADING] = 'info'

FileStatus.getSelectOptions = () =>
  Object.keys(FileStatus.label).map(val => ({ text: FileStatus.label[val], value: val }))

export default FileStatus
