import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'

Vue.use(VueRouter)

// TODO - clean up routes / remove unused
const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    meta: { sidebar: 'UploadFileFilters' },
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import('@/views/pages/Cookies.vue'),
  },

  // ADMIN

  {
    meta: { adminOnly: true },
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/AdminHome.vue'),
  },
  {
    meta: { adminOnly: true },
    path: '/admin/group/:groupUuid',
    name: 'admin-group',
    props: true,
    component: () => import('@/views/admin/AdminGroup.vue'),
  },
  {
    meta: { adminOnly: true },
    path: '/admin/workspace/:organisationUuid',
    name: 'admin-workspace',
    props: true,
    component: () => import('@/views/admin/AdminOrganisation.vue'),
  },
  {
    meta: { adminOnly: true, sidebar: 'AdminGroupFilters' },
    path: '/admin/list-groups',
    name: 'admin-list-groups',
    props: true,
    component: () => import('@/views/admin/AdminListGroups.vue'),
  },
  {
    meta: { adminOnly: true, sidebar: 'AdminOrganisationFilters' },
    path: '/admin/list-workspaces',
    name: 'admin-list-workspaces',
    props: true,
    component: () => import('@/views/admin/AdminListOrganisations.vue'),
  },
  {
    meta: { adminOnly: true, sidebar: 'AdminUserFilters' },
    path: '/admin/list-users/:organisationUuid?',
    name: 'admin-list-users',
    props: true,
    component: () => import('@/views/admin/AdminListUsers.vue'),
  },
  {
    meta: { adminOnly: true },
    path: '/admin/list-user-permissions/:email',
    name: 'admin-list-user-permissions',
    props: true,
    component: () => import('@/views/admin/AdminListUserPermissions.vue'),
  },

  // ERRORS / CATCHALL

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.meta?.adminOnly) {
    // entering an admin route - restrict it
    try {
      const user = await Auth.currentAuthenticatedUser()
      const groups = user?.signInUserSession?.accessToken?.payload?.['cognito:groups']
      const isAdmin = groups && (groups.includes('ADMIN') || groups.includes('SUPERADMIN'))
      if (isAdmin) {
        // ALLOW - IS ADMIN
        next()

        return
      }

      // DENY - NOT ADMIN
      next('/dashboard')

      return
    } catch {
      // ALLOW - NOT LOGGED IN (login form displayed / content not visible)
      next()

      return
    }
  }

  // SKIP (not restricted)
  next()
})

export default router
