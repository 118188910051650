import {
  mdiAccountEdit,
  mdiAlertCircle,
  mdiCheckCircle,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClipboardTextOutline,
  mdiClose,
  mdiDelete,
  mdiDotsVertical,
  mdiDownload,
  mdiFileUpload,
  mdiGoogleSpreadsheet,
  mdiImage,
  mdiKeyVariant,
  mdiLockReset,
  mdiMagnify,
  mdiMusic,
  mdiPencil,
  mdiPlusCircle,
  mdiRestart,
  mdiScriptTextOutline,
  mdiSquareEditOutline,
  mdiUpload,
  mdiUploadOff,
} from '@mdi/js'

export default {
  mdiAccountEdit,
  mdiAlertCircle,
  mdiCheckCircle,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClipboardTextOutline,
  mdiClose,
  mdiDelete,
  mdiDotsVertical,
  mdiDownload,
  mdiFileUpload,
  mdiGoogleSpreadsheet,
  mdiImage,
  mdiKeyVariant,
  mdiLockReset,
  mdiMagnify,
  mdiMusic,
  mdiPencil,
  mdiPlusCircle,
  mdiRestart,
  mdiScriptTextOutline,
  mdiSquareEditOutline,
  mdiUpload,
  mdiUploadOff,
}
