<template>
  <v-navigation-drawer value permanent app floating width="230" class="app-navigation-menu" :right="$vuetify.rtl">
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-8 pb-10"></div>

    <!-- Navigation Items -->
    <!-- <v-list v-if="User.isAdmin" expand shaped class="vertical-nav-menu-items pr-5 pb-10">
      <nav-menu-link title="Organisation Settings" :to="{ name: 'admin-list-groups' }"></nav-menu-link>
      <nav-menu-link title="Workspace Settings" :to="{ name: 'admin-list-workspaces' }"></nav-menu-link>
      <nav-menu-link title="Profile Settings" :to="{ name: 'dashboard' }"></nav-menu-link>
    </v-list>
    <div v-else class="d-flex items-center ps-6 pe-5 pt-14 pb-14"></div> -->
    <div class="d-flex items-center ps-6 pe-5 pt-14 pb-14"></div>
    <div class="vertical-nav-content">
      <component :is="this.$route.meta.sidebar" v-if="this.$route.meta.sidebar" />
    </div>
  </v-navigation-drawer>
</template>

<script>
import User from '@/classes/User'
import AdminUserFilters from '@/layouts/components/admin/AdminUserFilters.vue'
import AdminOrganisationFilters from '@/layouts/components/admin/AdminOrganisationFilters.vue'
import AdminGroupFilters from '@/layouts/components/admin/AdminGroupFilters.vue'
import UploadFileFilters from '../UploadFileFilters.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuLink,
    AdminUserFilters,
    AdminOrganisationFilters,
    AdminGroupFilters,
    UploadFileFilters,
  },
  setup() {
    return {
      User,
    }
  },
}
</script>

<style lang="scss" scoped>
@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}
</style>
