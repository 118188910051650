<template>
  <div class="mb-5">
    <v-card-title> Search and Filter </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="filterFilename"
          background-color="white"
          label="Filename"
          outlined
          @keyup.native="onFilterChange"
        />

        <v-select
          v-model="filterFileStatus"
          background-color="white"
          multiple
          clearable
          outlined
          :items="FileStatus.getSelectOptions()"
          label="File Status"
          @change="onFilterChange"
        />

        <v-select
          v-model="filterFileType"
          background-color="white"
          multiple
          clearable
          outlined
          :items="FileTypes.getSelectOptions()"
          label="File Type"
          @change="onFilterChange"
        />
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import EventBus from '@/classes/EventBus'
import FileStatus from '@/classes/FileStatus'
import FileTypes from '@/classes/FileTypes'

export default {
  data() {
    return {
      FileStatus,
      FileTypes,
      filterFilename: '',
      filterFileStatus: null,
      filterFileType: null,
    }
  },
  methods: {
    async onFilterChange() {
      const filterEvent = {
        filterFilename: this.filterFilename,
        filterFileStatus: this.filterFileStatus,
        filterFileType: this.filterFileType,
      }
      EventBus.$emit('uploadFileListFilter', filterEvent)
    },
  },
}
</script>
