const { Auth } = require('aws-amplify')

// https://serverless-stack.com/chapters/configure-aws-amplify.html
module.exports.amplify = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_APP_CLIENT_ID,
  },
  Storage: {
    region: process.env.VUE_APP_AWS_REGION,
    bucket: process.env.VUE_APP_UPLOAD_BUCKET,
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    level: 'private',
  },
  API: {
    endpoints: [
      {
        name: 'apigw',
        endpoint: `${process.env.VUE_APP_APP_ENDPOINT}/api`,
        region: process.env.VUE_APP_AWS_REGION,

        // This is to ensure we can protect the lambda with a cognito authorizer instead of an iam authoriser (which wont work when proxying requests). See README.md
        custom_header: async () => ({ Authorization: (await Auth.currentSession()).idToken.jwtToken }),
      },
    ],
  },
}
