export default key => {
  // the organisationUuid is the first segment of the key-path
  const organisationUuid = key.split('/')[0]

  // the identity that uploaded the object
  const cognitoIdentityId = key.split('/')[1]

  // the uploadUuid is contained in the filename
  const uploadUuid = key.split('/')[2].split('-').slice(0, 5).join('-')

  // the filename is the last bit
  // fix issue #81 - "*" in the uploaded file name results in 403
  const filename = key
    .split('/')[2]
    .split('-')
    .splice(5)
    .join('-')
    .replace(/\[\[ASTERISK\]\]/g, '*')

  return {
    organisationUuid,
    cognitoIdentityId,
    uploadUuid,
    filename,
  }
}
