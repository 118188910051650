import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCardTitle,[_vm._v(" Search and Filter ")]),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"label":"Username, Email","outlined":"","background-color":"white"},nativeOn:{"keyup":function($event){return _vm.onFilterChange.apply(null, arguments)}},model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}}),_c(VAutocomplete,{attrs:{"label":"Workspace","multiple":"","clearable":"","outlined":"","background-color":"white","items":_vm.organisationList,"item-value":"organisationUuid","item-text":"organisationName","no-data-text":"No results found"},on:{"change":_vm.onFilterChange},model:{value:(_vm.userOrganisation),callback:function ($$v) {_vm.userOrganisation=$$v},expression:"userOrganisation"}}),_c(VSelect,{attrs:{"label":"User Status","multiple":"","clearable":"","outlined":"","background-color":"white","items":_vm.userStatusOptions},on:{"change":_vm.onFilterChange},model:{value:(_vm.userStatus),callback:function ($$v) {_vm.userStatus=$$v},expression:"userStatus"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }