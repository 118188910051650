import icons from '@/utils/icons'

const FileTypes = {
  AUDIO: 'audio',
  ARTWORK: 'artwork',
  STEM: 'stem',
  METADATA: 'metadata',
  UNSUPPORTED: 'unsupported',

  getSelectOptions: () => [
    { text: 'Audio', value: FileTypes.AUDIO },
    { text: 'Artwork', value: FileTypes.ARTWORK },
    { text: 'Stem', value: FileTypes.STEM },
    { text: 'Metadata', value: FileTypes.METADATA },
  ],

  supportedExtensions: JSON.parse(process.env.VUE_APP_ALLOWED_FILE_EXTENSIONS),

  getFileTypeGroup: file => {
    const ext = file.filename.split('.').pop().toLowerCase()
    if (!FileTypes.supportedExtensions.includes(`.${ext}`)) {
      return FileTypes.UNSUPPORTED
    }
    if (file.filename.charAt(0) === '=') {
      return FileTypes.UNSUPPORTED
    }
    if (['jpg'].includes(ext) || ['image/jpeg'].includes(file.type)) {
      return FileTypes.ARTWORK
    }
    if (['wav', 'mp3', 'flac'].includes(ext) || ['audio/wav'].includes(file.type)) {
      return FileTypes.AUDIO
    }
    if (['zip'].includes(ext)) {
      return FileTypes.STEM
    }
    if (
      ['xls', 'xlsx'].includes(ext) ||
      ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)
    ) {
      return FileTypes.METADATA
    }

    return FileTypes.UNSUPPORTED
  },
}

FileTypes.icons = {}
FileTypes.icons[FileTypes.AUDIO] = icons.mdiMusic
FileTypes.icons[FileTypes.ARTWORK] = icons.mdiImage
FileTypes.icons[FileTypes.STEM] = icons.mdiMusic
FileTypes.icons[FileTypes.METADATA] = icons.mdiClipboardTextOutline

export default FileTypes
