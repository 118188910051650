import EventBus from '@/classes/EventBus'
import FileStatus from '@/classes/FileStatus'
import escapeRegExp from '@/utils/escapeRegExp'

const itemsPerPage = 100
let uploads = []
let currentPage = 1
let hasMore = false

const defaultSort = (a, b) => {
  if (a?.filename < b?.filename) return -1
  if (a?.filename > b?.filename) return 1

  return 0
}

export default {
  init(uploadsArray) {
    uploads = uploadsArray
  },
  isNextDisabled() {
    return !hasMore
  },
  totalCount: () => uploads.length,
  hasMaxFileCount: () => uploads.length >= process.env.VUE_APP_MAX_SUBMISSION_ROWS,
  getAllUploads() {
    return uploads
  },
  getAllUploadsOrdered() {
    return uploads.sort(defaultSort)
  },
  getAllAudioOrdered() {
    return uploads.filter(upload => upload.isAudio()).sort(defaultSort)
  },
  getPage(params = {}) {
    // Initialise params
    const { first, next, type, status, filename, sortBy = [], sortDesc = [] } = params
    let page = currentPage
    if (next) {
      page += 1
    }
    if (first) {
      page = 1
    }
    let [sortField] = sortBy
    let [isSortDesc] = sortDesc
    if (sortField === 'type') {
      sortField = 'typeGroup'
    }
    if (typeof sortField !== 'string') {
      sortField = 'filename'
    }
    if (typeof isSortDesc !== 'boolean') {
      isSortDesc = false
    }

    // Create a filename regex if needed
    const filenameRegex = filename ? new RegExp(escapeRegExp(filename), 'i') : null

    // Filter and sort
    const filteredUploads = uploads
      .filter(upload => {
        // Apply status filter
        if (status?.length && !status.includes(upload.getStatus())) {
          return false
        }

        // Apply file type filter
        if (type?.length && !type.includes(upload.typeGroup)) {
          return false
        }

        // Apply filename filter
        if (filename && !upload.filename.match(filenameRegex)) {
          return false
        }

        return true
      })
      .sort((a, b) => {
        // Sort on sort field
        const sortFieldCompare = a?.[sortField].localeCompare(b?.[sortField])
        if (sortFieldCompare !== 0) return sortFieldCompare

        // Secondary sort on filename
        return a.filename.localeCompare(b.filename)
      })

    if (isSortDesc) {
      filteredUploads.reverse()
    }

    currentPage = page
    const pageUploads = filteredUploads.slice(0, page * itemsPerPage)
    hasMore = filteredUploads.length > page * itemsPerPage

    return { Items: pageUploads, filteredCount: filteredUploads.length }
  },

  addUpload(newUpload) {
    this.upsertUpload(newUpload)
  },

  updateUpload(newUpload) {
    this.upsertUpload(newUpload)
  },

  upsertUpload(newUpload) {
    // Replace any existing record with same uploadUuid or filename
    const index = uploads.findIndex(
      upload => upload.uploadUuid === newUpload.uploadUuid || upload.filename === newUpload.filename,
    )
    if (index !== -1) {
      const oldUpload = uploads[index]

      // Ignore this update if file is currently uploading locally
      if (oldUpload.status === FileStatus.UPLOADING && newUpload.status !== FileStatus.UPLOADING) {
        return
      }

      // Update oldUpload using newUpload properties if same uuid
      if (newUpload.getId() === oldUpload.getId()) {
        uploads.splice(index, 1, newUpload)

        return
      }

      // Delete oldUpload
      EventBus.$emit('fileDeleted', oldUpload)
      uploads.splice(index, 1)
    }

    // Insert newUpload
    uploads.push(newUpload)
  },

  deleteUpload(uploadUuid) {
    const index = uploads.findIndex(upload => upload.uploadUuid === uploadUuid)
    if (index !== -1) {
      EventBus.$emit('fileDeleted', uploads[index])
      uploads.splice(index, 1)
    }
  },

  refreshAllErrors() {
    uploads.forEach(upload => {
      upload.refreshErrors()
    })
  },

  clearWorkspace() {
    uploads = []
    hasMore = false
    EventBus.$emit('clearWorkspace')
  },

  simpleErrorsCount() {
    let count = 0
    uploads.forEach(upload => {
      count += upload.simpleErrorsCount()
    })

    return count
  },

  hasErrors() {
    return !!uploads.find(upload => upload.hasErrors())
  },
}
