import { API } from 'aws-amplify'
import asyncRetry from '@/utils/asyncRetry'

const apiName = 'apigw'

const doApiRequest = async (endpoint, params = {}, method = 'post') =>
  asyncRetry(async () => {
    try {
      const response = await API[method](apiName, `/${endpoint}`, { body: params })

      return response
    } catch (error) {
      // return with response data if client error
      if (error?.response?.status === 400 && error?.response?.data) {
        return error.response.data
      }

      // retry on failure
      console.error(`API ERROR on '${endpoint}': ${error}`, error)
      throw error
    }
  })

export default {
  async listUploads(params) {
    return doApiRequest('listUploads', params)
  },
  async getOrganisation() {
    return doApiRequest('getOrganisation')
  },
  async getNewNotifications(params) {
    return doApiRequest('getNewNotifications', params)
  },
  async deleteUploads(params) {
    return doApiRequest('deleteUploads', params)
  },
  async crossValidate() {
    return doApiRequest('crossvalidate')
  },
  async submitWorkspace() {
    return doApiRequest('submit-workspace')
  },
  async clearWorkspace() {
    return doApiRequest('clearWorkspace')
  },
  async downloadMetaTemplate() {
    return doApiRequest('downloadMetaTemplate')
  },
  async changeSettings(params) {
    return doApiRequest('changeSettings', params)
  },
  async keepAlive() {
    return doApiRequest('keepAlive')
  },
  generatePresigned: async params => doApiRequest('generatePresigned', params),
  finaliseMultipart: async params => doApiRequest('finaliseMultipart', params),

  // ADMIN
  adminListGroups: async () => doApiRequest('admin/listGroups', undefined, 'get'),
  adminPersistGroup: async params => doApiRequest('admin/persistGroup', params),
  adminListOrganisations: async () => doApiRequest('admin/listOrganisations', undefined, 'get'),
  adminPersistOrganisation: async params => doApiRequest('admin/persistOrganisation', params),
  adminListUsers: async () => doApiRequest('admin/listUsers', undefined, 'get'),
  adminPersistUser: async params => doApiRequest('admin/persistUser', params),
  adminResetUser: async params => doApiRequest('admin/resetUser', params),
  adminDeleteUser: async params => doApiRequest('admin/deleteUser', params),
  adminListUserPermissions: async params => doApiRequest('admin/listUserPermissions', params),
  adminPersistPermission: async params => doApiRequest('admin/persistPermission', params),
  adminDeletePermission: async params => doApiRequest('admin/deletePermission', params),
}
