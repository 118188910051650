import EventBus from '@/classes/EventBus'
import { Auth, Hub } from 'aws-amplify'

let currentUser = null

const callFuncWithCurrentUser = func => {
  Auth.currentAuthenticatedUser()
    .then(func)
    .catch(() => {
      // not logged in yet
    })
}

const User = {
  isLoggedIn: false,
  getId: () => currentUser?.username,
  isAdmin: false,
  isSuperAdmin: false,
  organisationUuid: null,
  onUserLoaded: async func => {
    // call immediately if user already logged in
    if (User.isLoggedIn) {
      func(currentUser)
    }

    // call whenever a new user logs in (or out)
    EventBus.$on('onUserLoaded', func)
    EventBus.$on('logout', func)
  },
}

// function to initialise for a new user
const newUserInit = user => {
  currentUser = user

  // admin check
  const groups = user?.signInUserSession?.accessToken?.payload?.['cognito:groups']
  if (groups) {
    User.isSuperAdmin = groups.includes('SUPERADMIN')
    User.isAdmin = User.isSuperAdmin || groups.includes('ADMIN')
  }
  User.isLoggedIn = true
  if (currentUser) {
    currentUser.isSuperAdmin = User.isSuperAdmin
    currentUser.isAdmin = User.isAdmin
    currentUser.isLoggedIn = User.isLoggedIn
  }
  EventBus.$emit('onUserLoaded', user)
}

// call newUserInit immediately if already logged in
callFuncWithCurrentUser(newUserInit)

// auth event handling
Hub.listen('auth', data => {
  // call newUserInit whenever a new user logs in
  if (data.payload.event === 'signIn') {
    const user = data.payload.data
    if (user?.attributes?.email) {
      newUserInit(user)
    } else {
      callFuncWithCurrentUser(newUserInit)
    }
  }

  // handle logout event
  else if (data.payload.event === 'signOut') {
    User.isLoggedIn = false
    User.isAdmin = false
    User.isSuperAdmin = false
    if (currentUser) {
      currentUser.isLoggedIn = User.isLoggedIn
      currentUser.isSuperAdmin = User.isSuperAdmin
      currentUser.isAdmin = User.isAdmin
    }
    currentUser = null
    EventBus.$emit('logout')
  }
})

export default User
