/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */

const wait = ms =>
  new Promise(res => {
    setTimeout(res, ms)
  })

const maxAttempts = 3
const baseDelay = 300

export default async (fn, errorCallback) => {
  let attempt = 1
  let error = null
  while (attempt <= maxAttempts) {
    try {
      const result = await fn()

      return result
    } catch (_error) {
      error = _error
      console.error(`asyncRetry FAILED ATTEMPT #${attempt}`, { fn, error })
      if (errorCallback) {
        errorCallback()
      }
      if (attempt < maxAttempts) {
        await wait(2 ** attempt * baseDelay)
      }
      attempt++
    }
  }

  console.error('asyncRetry FAILED FINAL', { fn, error })
  throw error
}
