import EventBus from '@/classes/EventBus'
import UploadsApi from '@/classes/UploadsApi'
import User from '@/classes/User'
import onUserLoaded from '@/utils/onUserLoaded'

let organisations = []

const OrganisationsApi = {
  getNameById(id) {
    if (!organisations) {
      return 'Loading...'
    }
    const organisation = this.getById(id)

    return organisation?.organisationName || '???'
  },
  getById(id) {
    return organisations.find(o => o.organisationUuid === id)
  },
  getAll() {
    return organisations
  },

  async refresh() {
    if (User.isAdmin) {
      organisations = (await UploadsApi.adminListOrganisations())?.Items
    } else {
      organisations = []
    }
    EventBus.$emit('organisationsRefreshed', organisations)

    return organisations
  },

  onLoaded(func) {
    if (organisations.length) {
      func(organisations)
    }

    EventBus.$on('organisationsRefreshed', func)
  },
}

// Always load orgs
onUserLoaded(() => {
  OrganisationsApi.refresh()
})

EventBus.$on('organisationCreated', () => {
  OrganisationsApi.refresh()
})
EventBus.$on('organisationEdited', () => {
  OrganisationsApi.refresh()
})

export default OrganisationsApi
