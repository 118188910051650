// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript (modified)

const k = 1024
const logK = Math.log(k)
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']

export default (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'
  const dm = decimals < 0 ? 0 : decimals
  const i = Math.floor(Math.log(bytes) / logK)

  return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`
}
