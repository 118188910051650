import axios from 'axios'
import UploadsApi from './UploadsApi'
import asyncRetry from '../utils/asyncRetry'

export default class FileUploader {
  constructor(thisFile) {
    this.file = thisFile
    this.multipart = false
    this.parts = []
    this.partProgress = {}
    this.totalProgress = 0
    this.isAborted = false
    this.abortController = new AbortController()
  }

  async upload() {
    const thisFile = this.file
    const { size, type } = thisFile
    const presigned = await UploadsApi.generatePresigned({
      shortKey: thisFile.getShortKey(),
      size,
      type,
    })
    if (!presigned.success) {
      throw new Error(presigned.message || 'Error creating multipart')
    }

    this.quitIfAborted()

    const { multipart, url, fields, fileKey, fileUploadId, parts } = presigned.data
    this.multipart = multipart
    this.fileKey = fileKey
    this.fileUploadId = fileUploadId
    this.parts = parts
    if (multipart) {
      let uploadedParts
      try {
        uploadedParts = await Promise.all(parts.map(this.uploadPart, this))
      } catch (error) {
        await this.abort(error)
      }

      this.quitIfAborted()

      // all parts uploaded, finalise
      const finaliseResponse = await UploadsApi.finaliseMultipart({
        fileKey,
        fileUploadId,
        parts: uploadedParts,
      })
      if (!finaliseResponse.success) {
        const error = new Error(finaliseResponse.message || 'Error finalising multipart')
        await this.abort(error)
      }
    } else {
      // single part upload
      const form = new FormData()
      Object.keys(fields).forEach(k => form.append(k, fields[k]))
      form.append('file', thisFile.obj)
      await asyncRetry(
        () => {
          this.quitIfAborted()

          return axios.post(url, form, {
            signal: this.abortController.signal,
            onUploadProgress: progressEvent => thisFile.progressCallback(progressEvent),
          })
        },
        () => this.quitIfAborted(),
      )
    }
  }

  async uploadPart(partRaw) {
    const part = partRaw
    const { size, type } = this.file
    const { PartNumber, start, end, signedUrl } = part
    this.partProgress[PartNumber] = 0
    const chunk = this.file.obj.slice(start, end)
    const headers = { 'Content-Type': type }
    const partResponse = await asyncRetry(
      () => {
        this.quitIfAborted()

        return axios.put(signedUrl, chunk, {
          signal: this.abortController.signal,
          headers,
          onUploadProgress: progressEvent => {
            // Use the difference in progress of this part to calculate the total file progress
            const { loaded } = progressEvent
            const progressPrev = this.partProgress[PartNumber]
            const progressDiff = loaded - progressPrev
            this.partProgress[PartNumber] = loaded
            this.totalProgress += progressDiff

            return this.file.progressCallback({
              loaded: this.totalProgress,
              total: size,
            })
          },
        })
      },
      () => {
        this.quitIfAborted()

        // on error reset part progress
        this.totalProgress -= this.partProgress[PartNumber]
        this.partProgress[PartNumber] = 0
      },
    )

    const uploadedPart = {
      PartNumber,
      ETag: partResponse.headers.etag.replaceAll('"', ''),
    }

    return uploadedPart
  }

  async abort(abortError) {
    this.quitIfAborted()

    // set default abort error reason if not specified
    if (abortError) {
      this.abortError = abortError
    } else {
      const error = new Error('Upload has been cancelled')
      error.name = 'CanceledError'
      this.abortError = error
    }

    // set aborted flag
    this.isAborted = true

    // abort any upload parts in progress
    this.abortController.abort()

    // cleanup s3 bucket
    if (this.multipart) {
      const { fileKey, fileUploadId } = this
      await UploadsApi.finaliseMultipart({
        abort: true,
        fileKey,
        fileUploadId,
      })
    }

    // throw error
    throw abortError
  }

  quitIfAborted() {
    if (this.isAborted) {
      throw this.abortError
    }
  }
}
