<template>
  <v-app>
    <v-app-bar app fixed flat absolute color="primary">
      <div class="boxed-container w-full">
        <div id="appTopBar" class="d-flex align-center mx-6 white--text">
          <!-- Left Content -->

          <router-link to="/" style="color: white; text-decoration: none">
            {{ organisationName.toUpperCase() }} UPLOADER
          </router-link>

          <v-spacer></v-spacer>

          <!-- Right Content -->

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <vertical-nav-menu v-if="User.isLoggedIn && (User.isAdmin || this.$route.meta.sidebar)"></vertical-nav-menu>
        <AdminNavBar />
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2021
            <a href="https://www.sonymusicpub.com/" class="text-decoration-none" target="_blank"
              >Sony Music Publishing</a
            ></span
          >

          <span class="d-sm-inline d-none">
            <a href="/#/cookies" target="_blank" class="me-6 text--secondary text-decoration-none">Cookie Policy</a>
            <a
              href="https://www.sonymusicpub.com/en/privacy-policy"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
              >Privacy Policy</a
            >
            <a
              href="https://www.sonymusicpub.com/en/terms-and-conditions"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
              >Terms and Conditions</a
            >
          </span>
        </div>
      </div>
    </v-footer>

    <CookieBanner />
  </v-app>
</template>

<script>
import EventBus from '@/classes/EventBus'
import User from '@/classes/User'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import CookieBanner from './components/CookieBanner.vue'
import AdminNavBar from '../views/admin/AdminNavBar.vue'

export default {
  components: {
    CookieBanner,
    AppBarUserMenu,
    AdminNavBar,
    VerticalNavMenu,
  },
  data() {
    return {
      organisationName: 'Sony',
      User,
    }
  },
  mounted() {
    EventBus.$on('organisationLoaded', organisation => {
      this.organisationName = organisation.organisationName
    })
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
