<template>
  <div>
    <v-card-title> Search Workspaces </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="organisationSearch"
          label="Workspace Name, ID"
          outlined
          background-color="white"
          @keyup.native="onFilterChange"
        ></v-text-field>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import EventBus from '@/classes/EventBus'

// import Organisations from '@/classes/Organisations'

export default {
  data() {
    return {
      organisationSearch: '',

      // Organisations,
    }
  },

  // mounted() {
  //   Organisations.onLoaded(organisations => {
  //     this.organisationList = organisations
  //   })
  // },
  methods: {
    async onFilterChange() {
      // const valid = await this.$refs.form.validate()
      // if (!valid) {
      //   return
      // }

      const filterEvent = {
        organisationSearch: this.organisationSearch,
      }
      EventBus.$emit('adminOrganisationListFilter', filterEvent)
    },
  },
}
</script>
