<template>
  <v-list-item class="vertical-nav-menu-link" v-bind="$attrs" active-class="" active="false">
    <!-- <v-list-item-icon>
      <v-icon :class="{ 'alternate-icon-small': !icon }" class="mx-auto">
        {{ icon || alternateIcon }}
      </v-icon>
    </v-list-item-icon> -->

    <v-list-item-title>
      {{ title }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '~vuetify/src/styles/styles.sass';

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.v-list-item--link:before {
  background: none;
}
.v-list-item__title {
  font-size: 0.9rem;
}

.vertical-nav-menu-link {
  font-weight: bold;
  font-size: 10px;
  background: none;
}
</style>
