import { v4 as uuidv4 } from 'uuid'

export default class FileError {
  constructor() {
    this.uuid = null
    this.rowNum = null
    this.rowRef = null
    this.field = null
    this.message = '???'
  }

  static createFromApi(apiError) {
    const thisError = new FileError()
    thisError.uuid = apiError.uuid || uuidv4()
    thisError.rowNum = apiError.rowNum || null
    thisError.rowRef = apiError.rowRef || null
    thisError.field = apiError.field || null
    thisError.message = apiError.message || 'Unknown Error'

    return thisError
  }

  getUniqueKey() {
    return this.uuid
  }

  getTextSummary(displayRowCol = false) {
    if (!displayRowCol) {
      return this.message
    }

    return (
      (this.rowNum ? `Row ${this.rowNum}` : '') +
      (this.rowRef ? ` [${this.rowRef}]` : '') +
      (this.field ? `, Col "${this.field}"` : '') +
      (this.rowNum ? ` : ` : '') +
      this.message
    )
  }

  static sortDefault(a, b) {
    return (a.rowNum || 0) - (b.rowNum || 0)
  }
}
