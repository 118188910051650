require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#003CFD', // SONY blue
        accent: '#0d6efd',
        secondary: '#00ff00',
        success: '#33cc33', // green / VALID
        info: '#3366cc', // blue / UPLOADING
        warning: '#ffcc33', // yellow / PROCESSING
        error: '#cc3333', // red / INVALID
      },
      dark: {
        primary: '#003CFD', // SONY blue
        accent: '#0d6efd',
        secondary: '#00ff00',
        success: '#33cc33', // green / VALID
        info: '#3366cc', // blue / UPLOADING
        warning: '#ffcc33', // yellow / PROCESSING
        error: '#cc3333', // red / INVALID
      },
    },
  },
}
