<template>
  <div>
    <v-card-title> Search and Filter </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="userSearch"
          label="Username, Email"
          outlined
          background-color="white"
          @keyup.native="onFilterChange"
        ></v-text-field>

        <v-autocomplete
          v-model="userOrganisation"
          label="Workspace"
          multiple
          clearable
          outlined
          background-color="white"
          :items="organisationList"
          item-value="organisationUuid"
          item-text="organisationName"
          no-data-text="No results found"
          @change="onFilterChange"
        ></v-autocomplete>

        <v-select
          v-model="userStatus"
          label="User Status"
          multiple
          clearable
          outlined
          background-color="white"
          :items="userStatusOptions"
          @change="onFilterChange"
        />
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import Organisations from '@/classes/Organisations'
import EventBus from '@/classes/EventBus'

export default {
  data() {
    return {
      userSearch: '',
      userOrganisation: [],
      organisationList: [{ organisationName: 'Loading...' }],
      userStatus: [],
      userStatusOptions: [
        // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UserType.html
        'CONFIRMED',
        'UNCONFIRMED',
        'FORCE_CHANGE_PASSWORD',
        'RESET_REQUIRED',
        'EXTERNAL_PROVIDER',
        'UNKNOWN',
      ].map(value => ({ text: value, value })),
    }
  },
  mounted() {
    Organisations.onLoaded(organisations => {
      this.organisationList = organisations
    })
  },
  methods: {
    async onFilterChange() {
      const filterEvent = {
        userSearch: this.userSearch,
        userOrganisation: this.userOrganisation,
        userStatus: this.userStatus,
      }
      EventBus.$emit('adminUserListFilter', filterEvent)
    },
  },
}
</script>
