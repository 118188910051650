import { Storage } from 'aws-amplify'
import downloadBlob from '@/utils/downloadBlob'
import FileUploader from './FileUploader'

const getMetaFile = async (metaname, parseJson = false) => {
  try {
    const response = await Storage.get(metaname, {
      level: 'private',
      identityId: 'meta',
      download: true,
      cacheControl: 'no-cache',
    })
    const content = await response.Body.text()

    return content && parseJson ? JSON.parse(content) : content
  } catch (error) {
    return null
  }
}

export default {
  configureForUser({ attributes = {} } = {}) {
    Storage.configure({
      customPrefix: {
        private: `${attributes?.['custom:organisationUuid']}/`,
      },
    })
  },

  // If we need more control with respect to s3 uperations:
  // https://stackoverflow.com/questions/57887509/how-to-stop-aws-amplify-from-appending-level-and-identitypoolid-in-the-url-while
  async upload(thisFileRaw, completeCallback, errorCallback) {
    const thisFile = thisFileRaw
    try {
      const uploader = new FileUploader(thisFile)
      thisFile.uploader = uploader
      await uploader.upload()
      completeCallback()
    } catch (error) {
      errorCallback(error)
    }
  },

  async cancel(thisFile) {
    try {
      await thisFile?.uploader?.abort()
    } catch (error) {
      // ignore exception
    }

    // remove uploader object
    // eslint-disable-next-line no-param-reassign
    delete thisFile.uploader
  },

  async download(thisFile) {
    // provide the identityId to access files from other users
    const [, identityId, shortKey] = thisFile.key.split('/')
    const response = await Storage.get(shortKey, { identityId, level: 'private', download: true })
    downloadBlob(response.Body, thisFile.filename)

    return null
  },

  async getAppErrorsCache() {
    return getMetaFile('app_errors.json', true)
  },

  async getCrossValErrorsCache() {
    return getMetaFile('cross_errors.json', true)
  },

  async getMetaDownloadCache() {
    return getMetaFile('download.xlsx')
  },
}
