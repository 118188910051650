<template>
  <v-card v-if="User.isAdmin && this.$route.meta.adminOnly" class="mb-5">
    <v-card-title> Admin Area </v-card-title>
    <v-card-text>
      <router-link :to="{ name: 'admin-list-users', params: { organisationUuid: User.organisationUuid } }">
        Users<!--
      --></router-link>
      &nbsp;
      <router-link :to="{ name: 'admin-list-workspaces', params: { organisationUuid: User.organisationUuid } }">
        Workspaces<!--
      --></router-link>
      &nbsp;
      <router-link :to="{ name: 'admin-list-groups' }">
        Organisations<!--
      --></router-link>
    </v-card-text>
  </v-card>
</template>

<script>
import User from '@/classes/User'

export default {
  data() {
    return {
      User,
    }
  },
}
</script>
